<template><span></span></template>

<script>
  import router from '../../router'

  export default {
    name: 'PaymentSuccess',
    created () {
      var self = this
      this.$store.dispatch('apiPayment/action_getPaymentResult', {
        paymentRefCode: this.$route.query.paymentRefCode,
        resultIndicator: this.$route.query.resultIndicator,
      })
        .then((response) => {
          var paymentText = 'An error occurred during payment.'
          if (response.status === 200 && response.data.Paid === true) {
            paymentText = 'Your order has been successfully paid.'
          }
          self.$store.commit('orders/SUCCESS_PAGE_ALLOW_ACCESS_ADD_OID', response.data.Order.Oid)
          router.push({
            name: 'Order/Success',
            params: {
              oid: response.data.Order.Oid,
              extraText: paymentText,
            },
          })
        })
        .catch((err) => {
          console.log('error', err)
          router.push({ name: 'Account/MyOrders' })
        })
    },
  }

</script>
